
export default {
    home: {
        lenovoSmartRetail: '联想智慧零售',
        homePage: '首页',
        productCenter: '产品中心',
        solution: '解决方案',
        industryCase: "行业案例",
        serviceForce: "服务支持",
        aboutUs: "关于我们",
        trial: "申请试用",
        lenovoSmartRetailProduct: "联想智慧零售产品",
        lenovoSmartRetailTechnolagyScheme: "联想智慧零售技术架构",
        technolagySchemeContent:"以联想新零售体系为核心，通过“端-边-云-网-智”的基础架构，利用AI智能技术，加速线上线下全域融合，赋能“人、货、场”要素重构 打造以“全场域、高效率、精准化”为主要特征的零售新业态，提升消费者体验的同时，最大化品牌产出和运营效率。",
        learnMore: '了解详情',
        smartShop:'智慧门店',
        smartShopContent: '为连锁零售门店提供一站式数字解决方案，助力零售场景多元化融合',
        smartMiddleGround: '智慧中台',
        smartMiddleGroundContent: '构建业务中台，充分利用数据价值，以数据驱动运营，为企业统一管理全渠道运营',
        smartHardware: "智能硬件",
        smartHardwareContent: "基于联想智能硬件及运维能力，打造数字化线下门店",
        shopWorryFree: "门店无忧",
        shopWorryFreeContent: "覆盖全国的交付体系及服务资源，提供线下门店基础设施服务、新开店服务和运维支持服务等",
        solutionSnipTitle: "联想智慧零售解决方案",
        solutionContent: "以后端智能化能力赋能前端智慧零售场景，将智慧零售全场景、多业态交织融合，实现以消费者为中心的、个性化、多层级全旅程购物需求\n联想智慧零售解决方案，多维度赋能，全面助力企业数字化转型。",
        smartPutSolution: '智能投放\n解决方案',
        smartPutSolutionHover: '智能投放解决方案',
        smartPutSolutionContent: "为企业实现广告营销活动实时、精准、互动的软硬一体化智能投放系统解决方案。",
        smartPassengerFlowSolution: '智慧客流\n解决方案',
        smartPassengerFlowSolutionHover: '智慧客流解决方案',
        smartPassengerFlowSolutionContent: '对客流进行数据可视化管理，通过用户轨迹和行为分析，建立用户和品牌产品的联系，实现可视化精准营销。',
        smartSecurityPatrolSolution: '智慧安防巡店\n解决方案',
        smartSecurityPatrolSolutionHover: '智慧安防巡店解决方案',
        smartSecurityPatrolSolutionContent: '随时随地进行智能巡店，360度全方位无死角的进行门店管理，实现PDCA业务闭环。',
        smartMiddleGroundSolution: '智慧中台\n解决方案',
        smartMiddleGroundSolutionHover: '智慧中台解决方案',
        smartMiddleGroundSolutionContent: '帮助企业迅速构建业务中台，为企业统一处理各系统及外部数据，实现统一管理全渠道运营的解决方案。',
        foundDetail:'查看详情',
        findMoreSolution: '查看更多解决方案',
        coreAdvantage: '核心优势',
        coreAdvantageCard1Title: "零售业务数字化，线上线下一体化",
        coreAdvantageCard1Text: "从前端零售门店（触达）到中端业务运营（支撑）再到后端数据分析（提升）逐步深入，结合线上线下全渠道资源整合，圈定人、选定货、升级场，实现全域能力融合。",
        coreAdvantageCard2Title: "企业全面上云，服务弹性化，能力可扩展",
        coreAdvantageCard2Text: "大幅降低前期一次性投入成本，后期服务弹性配置，灵活可控；坐拥专业的系统运维团队保证系统的稳定运行及数据安全；无缝升级策略让您的系统始终保持业界领先。",
        coreAdvantageCard3Title: "全面门店洞察，升级消费体验",
        coreAdvantageCard3Text: "感知消费者从过店、进店到离店的全过程，掌握消费者购物动线，把握消费者选购心态，了解消费者消费习惯，以数据为基础全面提升消费体验，最大程度提高消费转化。",
        coreAdvantageCard4Title: "会员数字化运营，实现精准营销触达",
        coreAdvantageCard4Text: "统一全域会员权益及体系，以大数据多维度的分析手段，构建科学的标签体系，洞察客群习性，打通多种营销渠道，准确触达目标消费者。",
        coreAdvantageCard5Title: "完备的硬件设备支持，实现数据全面收集",
        coreAdvantageCard5Text: "全零售终端支持，包含智能大屏、智能摄像头、智能收银机、票据打印机、扫码枪以及支持货品库存管理的PDA和RFID采集设备，出厂即与软件无缝结合，一键配齐。",
        coreAdvantageCard6Title: "一站式门店IT运维服务，保障业务稳定运行",
        coreAdvantageCard6Text: "依托联想ServiceForce智能平台、全国100%覆盖的交付体系及服务资源，提供线下门店基础设施服务、门店运维服务以及门店运营维护服务，帮助企业降低运维成本，关注核心业务。",
        industryCase: "行业案例",
        industryCaseCard1Title: "来酷科技",
        industryCaseCard1Text: "联想供应链引擎，助力来酷成为智慧零售领军企业",
        industryCaseCard2Title: "大型餐饮连锁企业",
        industryCaseCard2Text: "联想门店无忧服务，助力餐饮连锁企业实现下沉市场开店战略，实现统一门店运营提供服务保障",
        industryCaseCard3Title: "全球最大咖啡企业",
        industryCaseCard3Text: "联想助力全球最大咖啡企业，实现门店精准预测补货",
        queryMoreIndustyCase: "查看更多行业案例",
        pageFootColumn1Title: "产品中心",
        pageFootColumn1Text1: "智慧门店",
        pageFootColumn1Text2: "智慧中台",
        pageFootColumn1Text3: "智能硬件",
        pageFootColumn1Text4: "门店无忧",
        
        pageFootColumn2Title: "解决方案",
        pageFootColumn2Text1: "智慧零售解决方案",
        pageFootColumn2Text2: "智慧门店解决方案",
        pageFootColumn2Text3: "智慧中台解决方案",

        pageFootColumn3Title: "权威认证",
        pageFootColumn3Text1: "ISO 9001-2008\n质量管理体系认证",
        pageFootColumn3Text2: "ISO/IEC20000-1:2005\nIT服务管理体系认证",
        pageFootColumn3Text3: "ISO/IEC27001-1:2005\n信息安全管理体系认证",
        pageFootColumn3Text4: "等保三级\n信息系统安全等级保护",

        pageFootColumn4Title: "联系我们",
        pageFootColumn4Text1: "4006100909-5",
        pageFootColumn4Text2: "4008105877-1",
        pageFootColumn4Text3: "SSG_China_Commercial_Smart_Retail{'@'}lenovo.com",
        pageFootColumn4Text4: "北京市海淀区西北旺东路10号联想全球总部",

        pageFootTipSnip1: "版权所有：1998-2022 联想集团",
        pageFootTipSnipBar: "{'|'}",
        pageFootTipSnip2: " 法律公告",
        pageFootTipSnip3: " 隐私权政策",
        pageFootTipSnip4: " 产品安全",
        pageFootTipSnip5: " 津ICP备2022007281号-1",
        pageFootColumnWorkTime: "(工作日09:00～18:00)",
    },
    productCenter:{
        menu:{
            menuItemShop:"智慧门店",
            menuItemMiddleGround:"智慧中台",
            menuItemHardware:"智能硬件",
            menuItemWorryFree:"门店无忧",
        },
        shop:{
            
            lenovoSmartShop: "联想智慧门店",
            lenovoSmartShopContent1: "联想智慧门店系统，为企业提供数字化协同，从消费者进店、体验、决策、购买等环节帮助门店提升客户体验与服务，实现智慧门店的数字化转型。",
            
            programmeTitle: "联想智慧门店方案内容",
            programmeTitleCard1: "联想智能投放解决方案",
            programmeTitleCard2: "联想智慧客流解决方案",
            programmeTitleCard3: "联想安防巡店解决方案",
            programmeTitleCard4: "联想互动展示解决方案",
            programmeTitleCard5: "联想配搭推荐解决方案",
            programmeTitleCard6: "联想智慧导购解决方案",
            programmeTitleCard7: "联想智慧会员解决方案",
            programmeTitleCard8: "联想智慧收银解决方案",
            
            applicableHardwareTitle: "联想智慧门店适用硬件",
            applicableHardwareColumnTitle1: '安防客流',
            applicableHardwareColumnTitle2: '互动展示',
            applicableHardwareColumnTitle3: '门店收银',
            applicableHardwareColumnTitle4: '网络管理',

            applicableHardwareCard1: "摄像头",
            applicableHardwareCard2: "录像机",
            applicableHardwareCard3: "边缘计算机",

            applicableHardwareCard4: "智能电视",
            applicableHardwareCard5: "广告机",
            applicableHardwareCard6: "高清拼接屏及播放器",

            applicableHardwareCard7: "移动收银机",
            applicableHardwareCard8: "台式收银机及外设",
            applicableHardwareCard9: "自助收银机",

            applicableHardwareCard10: "无线AP",
            applicableHardwareCard11: "路由器",
            applicableHardwareCard12: "交换机",
        },
        middleGround: {
            
            lenovoSmartMiddleGround: "联想智慧中台",
            lenovoSmartMiddleGroundContent1: "联想智慧中台帮助企业构建业务中台，统一处理各系统及外部数据，充分利用数据价值，以数据驱动运营，便于企业统一管理全渠道运营的业务模块。",
            
            coreFunc: "联想智慧中台核心功能",
            coreFuncCard1: "通过智慧中台不但收集企业内部各个系统的离散碎片化数据，同时收集企业外部的各种有价值数据，对数据进行统一的规范化处理。",
            coreFuncCard2: "对规化处理的体系数据，进行高效计算，通过数据服务实时反馈到各业务模块，使得数据真正成为生产资料。",
            coreFuncCard3: "帮助企业迅速构建业务中台，便于企业统一管理全渠道运营的业务模块。",
            
            middleProgramme: '联想智慧中台方案内容',
            middleProgrammeCard1: '联想智慧采购解决方案',
            middleProgrammeCard2: '联想智慧仓储解决方案',
            middleProgrammeCard3: '联想智慧分销解决方案',
            middleProgrammeCard4: '联想智慧运营解决方案',

            middleProgrammeCard5: '联想业绩管理解决方案',
            middleProgrammeCard6: '联想智慧店助解决方案',
            middleProgrammeCard7: '联想智慧收银解决方案',
            middleProgrammeCard8: '联想门店进销存解决方案',

            middleProgrammeCard9: '联想智慧导购解决方案',
            middleProgrammeCard10: '联想私域营销解决方案',
            middleProgrammeCard11: '联想全域营销解决方案',
            middleProgrammeCard12: '联想智慧财务解决方案',

            applicableHardwareTitle: "联想智慧中台适用硬件",
            applicableHardwareColumnTitle1: '门店收银',
            applicableHardwareColumnTitle2: '仓储作业',
            applicableHardwareColumnTitle3: '安防监控',
            applicableHardwareColumnTitle4: '网络管理',

            applicableHardwareCard4: "RFID读写器及标签",
            applicableHardwareCard5: "PDA",
        },
        hardware:{
            hardwareUseTitle: "联想智能硬件应用场景全景",

            securityMonitoring: "安防监控类",
            securityMonitoringCard1: "Wifi球型摄像机",
            securityMonitoringCard2: "红外枪型摄像机",
            securityMonitoringCard3: "云台摄像机",
            securityMonitoringCard4: "NVR录像机",

            put: "智能投放类",
            putCard1: "拼接屏",
            putCard2: "商用显示大屏",
            putCard3: "广告机",
            putCard4: "多媒体盒子",

            cashier: "门店收银类",
            cashierCard1: "移动收银机",
            cashierCard2: "台式收银机及外设",
            cashierCard3: "自助收银",

            interaction: "互动展示类",
            interactionCard1: "RFID一体机",
            interactionCard2: "RFID读写器",
            interactionCard3: "RFID天线",

            network: "网络类",
            networkCard1: "AP",
            networkCard2: "路由器",
            networkCard3: "交换机",
            networkCard4: "传感器",
        },
        worryFree: {
            worryfreeService: "联想门店无忧服务",
            content1: "联想门店无忧服务，为零售门店提供一系列基于设备和服务的端到端解决方案，联想作为领先的智能化转型专业服务提供商，利用自身强大的供应链体系和IT行业最大的服务网络，能够为企业提供的服务包括：开关店服务、产品与服务采购、门店设备安装与运维、供应链交付等一站式的门店全生命周期服务，全面助力企业实现零售业务的数字化转型。",
            content2: "基于联想智能化硬件及运维能力打造数字化线下门店，并依托联想ServiceForce智能平台，全国100%覆盖的交付体系及服务资源，提供线下门店基础设施服务、新开门店服务、运维支持服务及改造/拆除/回收服务，实现零售门店数字化运营。",
            content3: "联想门店无忧服务，为零售企业的IT业务保驾护航"
        }
    },
    solution:{
        menu:{
            menuRetail:"智慧零售解决方案",
            menuShop:"智慧门店解决方案",
            menuMiddleGround:"智慧中台解决方案",

            subShopPut:"智能投放解决方案",
            subShopPassengerFlow:"智慧客流解决方案",
            subShopSecurityInspection:"安防巡检接机方案",
            subShopInteractionDisplay:"互动展示解决方案",
            subShopMatchingRecommend:"搭配推荐解决方案",
            subShopGuide:"智慧导购解决方案",
            subShopPurchase:"联想采购解决方案",
            subShopStock:"联想仓储解决方案",
            subShopMember:"智慧会员解决方案",
            subShopCashier:"智慧收银解决方案",
            subShopNetwork:"智慧网络解决方案",
            subShopPurchaseSaleInventory:"门店进销存解决方案",

            subMiddleGroundPurchase: "智慧采购解决方案",
            subMiddleGroundStock: "智慧仓储解决方案",
            subMiddleGroundDistribution: "智慧分销解决方案",
            subMiddleGroundOperate: "智慧运营解决方案",
            subMiddleGroundPerformance: "业绩管理解决方案",
            subMiddleGroundShopAssistant: "智慧店助解决方案",
            subMiddleGroundPurchaseSaleInventory: "门店进销存解决方案",
            subMiddleGroundPrivateSphere: "私域营销解决方案",
            subMiddleGroundPublicSphere: "全域营销解决方案",
            subMiddleGroundFinance: "智慧财务解决方案",
        },
        retail:{
            text1: "联想智慧零售解决方案结合了联想在“端-边-云-网-智”领域先进经验，针对新一代消费者需求，为零售企业打造的覆盖商品企划、采购、仓储、零售、会员、财务及数据分子等整个销售链环节的整套软件、硬件及服务解决方案。\n\n通过优化门店体验、打通供应链、洞察市场需求，建立以数据为驱动的智慧运营体系，为客户提供大数据提供精准分析，预测及引导企业发展决策。",
            title1: "智慧零售解决方案为连锁零售门店提供一站式数字化解决方案\n及管家式IT运维服务",
            title2: "联想智慧零售核心业务场景",
            title3: "联想智慧零售解决方案优势",
            text2: "从技术视角，平台依托大数据技术，建立以数据为驱动的智慧运营体系，为企业发展、业务决策提供精准科学的数据支撑。依托物联网、智能IoT设备以及AI人工智能技术，了解顾客需求，提升消费体验，最大化品牌产出和运营效率。\n\n从业务视角，为企业提供全面洞察能力，从前端零售门店（触达）到中端业务运营（支撑）再到后端数据分析（提升）逐步深入，结合线上线下全渠道资源整合，圈定人、选定货、升级场，实现全域能力融合，提升企业核心业务竞争能力。\n\n同时结合联想自身优势，为零售企业提供“软件+硬件+服务”的一站式解决方案，大幅降低企业IT投入成本，提升企业服务效率及水平，全面赋能企业业务及IT数字化转型。",
            title4: "联想智慧零售客户收益"
        },
        shop: {
            putTitle: "智能投放解决方案",
            putContent: "为企业打造一套实现广告营销活动实时、精准、互动的软硬一体化智能投放系统解决方案，让广告营销活动从总部即可一键投放到指定区域设备，让广告营销活动在合适的时间、以合适的方式投放到合适的目标客户，实现投放效果最佳化。",
            putCard1Title: "核心功能",
            putCard1Text1: "1.播放设备统一监控",
            putCard1Text2: "2.播放素材统一管理",
            putCard1Text3: "3.播放策略统一配置",
            putCard1Text4: "4.播放内容统一下发",
            putCard2Title: "方案价值",
            putCard2Text1: "播放内容将会通过智慧屏APP分发到不同的终端，无需门点自行操作即可自动播放，便捷高效。",

            passengerFlowTitle: "智慧客流解决方案",
            passengerFlowContent: "通过店内不同位置的传感设备，对客流进行数据可视化管理，通过用户轨迹，进行用户行为分析，建立客户和产品之间的联系，提供展陈优化数据支持，进行可视化营销。自动创建线下客户档案，并准确识别VIP客户和回访客户。",
            passengerFlowCard1Title: "核心功能",
            passengerFlowCard1Text1: "记录店外客流、进店客流、停留时间分布数据，采购价格分析等。",
            passengerFlowCard1Text2: "客户和产品之前建立联系，为展陈优化提供数据支持。",
            passengerFlowCard1Text3: "跟踪整个客户旅程的店内轨迹，精确到秒级/厘米级。",
            passengerFlowCard1Text4: "自动创建线下客户档案，准确识别VIP客户/回访客户（特定合规场景）。",
            passengerFlowCard2Title: "方案价值",
            passengerFlowCard2Text1: "帮助企业进行客流管理，实现可视化的精准营销。",

            securityInspectionTitle: "智慧安防巡店解决方案",
            securityInspectionContent: "随时随地进行线上远程巡店，在线截图、录像、分析等。门店运营人员也可以现场通过智能巡店，进行线下移动巡店，360度全方位无死角地进行门店管理，实现PDCA业务闭环。",
            securityInspectionCard1Text1: "高度集成：集图像采集、图像识别、数据传输于一体。",
            securityInspectionCard1Text2: "易扩展：采用非定制的普通摄像头，方便与第三方适配。",
            securityInspectionCard1Text3: "AI加持：充分利用AI能力实现业务的自动化和智能化。",
            securityInspectionCard2Text1: "辅助店面做好安防工作，\n进行店规检查、遗失告警、惯偷预警。",

            interactionDisplayTitle: "多屏互动展示解决方案",
            interactionDisplayContent: "利用多样化的传感器结合共享屏幕实现商品的自动化展示，当顾客拿起商品时数据上报，并在屏幕上展示商品详情。目前支持的传感器包括位移传感器、重力传感器、RFID传感器。",

            recommendTitle: "搭配推荐解决方案",
            recommendContent: "结合RFID技术，自动感知进入试衣间内的所有商品，记录商品试穿次数\n识别试穿商品，并在屏幕上展示模特照片，为消费者展示标准着装效果\n依据大数据分析，智能为消费者推荐可选搭配商品，提升销售连带。",
            recommendContent1: "结合RFID技术，自动感知进入试衣间内的所有商品，记录商品试穿次数。",
            recommendContent2: "识别试穿商品，并在屏幕上展示模特照片，为消费者展示标准着装效果。",
            recommendContent3: "依据大数据分析，智能为消费者推荐可选搭配商品，提升销售连带。",

            guideTitle: "智慧导购解决方案",

            memberTitle: "智慧会员解决方案",
            memberContent: "通过数据分析，将用户分级，做精细化运营，通过会员卡、会员积分、会员储值、会员等级等权益活动，最大化发挥用户价值。",
            memberCard1Text1: "数据分析", 
            memberCard1Text2: "用户画像",
            memberCard2Text1: "打造会员体系，实现精准营销。",

            cashierTitle: "智慧收银解决方案",
            cashierContent: "打通多个收银端口，方便企业在多种场景下进行收银操作。",
            cashierCard1Text1: "支持银联聚合支付、微信、支付宝、现金、刷卡、积分、储值卡、优惠券等多种支付方式。",
            cashierCard1Text2: "触屏版、自助版同时支持支付宝刷脸支付。",
            cashierCard1Text3: "桌面版系统，支持无线网络的线下收银模式，保证门店持续经营。",
            cashierCard1Text4: "单品、整单折扣，满减，满赠，各种促销活动灵活组合，方案实时下发，集合强制又可手动。",
            cashierCard2Text1: "多端口融合互通\n方便门店多场景进行收银操作。",

            networkTitle: "智慧网络解决方案",
        },
        middleGround:{
            purchaseTitle: "智慧采购解决方案",
            purchaseContent: "通过手机端联想买手APP。一键进行数据分析，管理商品及供应商档案，并实现简单快捷的货品跟单。",
            purchaseCard1Text1: "高度集成：集图像采集、图像识别、数据传输于一体。",
            purchaseCard1Text2: "易扩展：采用非定制的普通摄像头，方便与第三方适配。",
            purchaseCard1Text3: "AI加持：充分利用AI能力实现业务的自动化和智能化。",
            purchaseCard2Text1: "辅助店面做好安防工作，\n 进行店规检查、遗失告警、惯偷预警。",

            stockTitle: '智慧仓储解决方案',
            stockContent: '通过联想仓管，企业可随时查看采购到货、门店退货、配货拣货等情况，并进行货品的盘点和查询，一键实现货物的入库及出库管理。',
            stockCard1Text1: "代办任务提醒",
            stockCard1Text2: "货品库存作业",
            stockCard1Text3: "入库作业",
            stockCard1Text4: "出库作业",
            stockCard1Text5: "货物分拣",
            stockCard1Text6: "货物发运",
            stockCard2Text1: "1.多维配货模式，即可按款、也可按店。\n2.库存组织、仓库、虚拟仓库可自由制定。\n3.支持直配，货物无需到仓，直接到门店。",

            distributionTitle: "智慧分销解决方案",

            operateTitle: "智慧运营解决方案",

            performanceTitle: "业绩管理解决方案",

            shopAssistantTitle: "智慧店助解决方案",            

            purchaseSaleInventoryTitle: "门店进销存解决方案",
            purchaseSaleInventoryContent: "门店可以通过系统，全面了解公司公告，并进行门店管理。",
            purchaseSaleInventoryCard1Text1: "天气情况查询",
            purchaseSaleInventoryCard1Text2: "代办事项提醒",
            purchaseSaleInventoryCard1Text3: "商品排行查询",
            purchaseSaleInventoryCard1Text4: "公司公告查询",
            purchaseSaleInventoryCard1Text5: "业绩跟踪",
            purchaseSaleInventoryCard1Text6: "促销活动查询",
            purchaseSaleInventoryCard1Text7: "员工生日提醒",
            purchaseSaleInventoryCard2Text1: "方便门店管理者全面了解门店情况，\n辅助店面管理运营。",

            privateSphereTitle: "私域营销解决方案",
            publicSphereTitle: "全域营销解决方案",
            financeTitle: "智慧财务解决方案",
        },
    },
    industryCase: {
        card1Title: "联想供应链引擎助力来酷成为智慧零售领军企业",    
        card1Text: "来酷科技有限公司是一家专注于3C+品质潮品的智慧零售连锁企业，是新零售的独角兽以及中国制造新行业的议员。目前，来酷已在京津等地设立7大产业中心，在长沙重庆等66个城市建立350家智慧零售门店，并利用4年时间打造出估值千亿的数值。面对快速增长的门店数量与商品种类，来酷的供应链管理难度也与日俱增，对供应链优化的需求 日益突出。联想智慧零售解决方案助力来酷破局线下零售瓶颈，全面云华线下门店“人”“货”“场”，为消费者提供全新全时全域的购物体验。",
        card2Title: "联想助力全球最大咖啡企业，实现门店精准预测补货",
        card2Text: "全球最大咖啡连锁零售商，入驻中国后迅速扩张并拥有3600家门店，是为数不多的本土化落地成功的咖啡连锁店。在从中国一线成功是向新一线城市下沉的门店扩张过程中，由于二、三线市场人力资源的局限性，该企业对门店智能预测补货有着迫切需求。",
        card3Title: "深圳美丽穿服装有限公司",
        card3Text: "“美丽穿”品牌服饰坐落于美丽的服装之都-湖南株洲，“美丽穿”品牌服饰的前身是湖南超越服饰有限公司，公司成立于2008年，经过8年时间的深耕细作，不断摸索前行，于2016年创立“美丽穿”快时尚女装品牌，品牌以经营18-35岁“欧、日、韩“等潮流服饰为主营。”美丽穿“在2018年门店数量增加到180家，体量的不断增加，对系统稳定性需求迫切，联想智慧零售为”美丽穿“提供智慧中台解决方案，通过一套完善的系统陪伴企业成长的发展需求。",
        card4Title: "某大型餐饮连锁企业，新零售统一数字化门店运维",
        card4Text: "联想智慧零售解决方案为企业提供开关店服务、门店设备运维等一站式全生命周期的服务，全面助力企业实现零售业务的数字化转型。",
    },
    aboutUs: {
        title1: "联想大客户方案服务业务是中国领先的IT解决方案和服务提供商",
        title1Sub: "助力商用大客户智能化转型",
        text1: "联想大客户方案服务是专业的IT设备全生命周期服务及智能化方案服务提供商，围绕“端边云网智”架构为基础，以“擎天”技术引擎为核心，全面提供面向商用客户的设备支持、IT运维管理、系统集成及各行业智慧化解决方案服务，推进行业智能化转型。联想大客户方案服务将以全栈的产品线和全周期的服务，赋能中国智能化进程加速发展，开启“领航新IT”的新征程。",
        title2: "联想智慧服务20余载不断创新",
        title3: "联想大客户方案服务业务分类",
        title4: "中国最大的服务交付体系",
        title5: "全领域的行业资质",
    },
    supply: {
        whatSupplyTitle: "您有哪方面的系统试用需求？",
        whatSupplyCard1: "智能投放",
        whatSupplyCard2: "智慧客流",
        whatSupplyCard3: "安防巡检",
        whatSupplyCard4: "多屏互动展示",
        whatSupplyCard5: "搭配推荐",

        whatSupplyCard6: "智慧导购",
        whatSupplyCard7: "智慧会员",
        whatSupplyCard8: "智慧收银",
        whatSupplyCard9: "智慧网络",
        whatSupplyCard10: "智慧采购",

        whatSupplyCard11: "智慧仓储",
        whatSupplyCard12: "智慧分销",
        whatSupplyCard13: "进销存",
        whatSupplyCard14: "智慧财务",
        whatSupplyCard15: "会员营销",

        describeTitle: "您的试用意向需求/说明",
        describePlaceholder: "您可以提交对智能硬件、软件、解决方案和服务的意向需求，便于我们跟进和提供服务支持",

        connectInfoTitle: "联系人信息",
        connectInfoSubTitle: "（请填写相关信息，客户经理将在1个工作日内与您联系）",
        input1Label: "姓名",
        input1Placeholder: "请填写真实姓名",
        input2Label: "公司名称",
        input2Placeholder: "请输入公司名称",
        input3Label: "联系电话",
        input3Placeholder: "请输入联系号码",
        input4Label: "职位",
        input4Checkbox1: "普通用户",
        input4Checkbox2: "IT管理者",
        input4Checkbox3: "业务运营",
        input4Checkbox4: "采购",
        input4Placeholder: "请选择您的职位",
        input5Label: "联系邮箱",
        input5Placeholder: "请输入邮箱",
        submit: "提交",
        demandErrorText: "请选择您的系统试用需求",
        submitSuccessTitle: "提交成功",
        submitSuccessText: "我们将在1个工作日内与您联系\n请您保持联系方式畅通",
        sendEmailFailText: "信息发送失败，请稍后重试！",
        testEmail: "请输入正确的邮箱格式",
        testPhone: "请输入正确的手机号码",
    },
    suspension:{
        connectionUs: "联系我们",
        connectionUsContent: "咨询电话：4006100909-5\n4008105877-1",
        lineAdvice: "在线咨询",
        tryApply: "申请试用",
        officialAccount: "公众号",
    }
}